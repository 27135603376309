.MainHeader {
  margin-top: 0;
  background-image: url('../../assets/AMR.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  max-width: 100vw;
}

/* .MuiTypography-root {
  color: #fff !important;
  font-family: "Allerta", Sans-serif !important;
  text-shadow: '0 0 10px #000';
} */